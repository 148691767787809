import React from 'react';
import { Layout } from '../components/shared/Layout';

const CookiePolicyPage = (): JSX.Element => {
  const NAME = 'Haagsche Schil';
  const EMAIL = 'info@urbanlink.nl';

  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Cookie policy</h1>
            <h3>What are cookies?</h3>
            <p>
              Cookies are small text files placed on your computer, tablet or
              mobile phone when you use the Service. Cookies are not capable or
              harming your computer, tablet or mobile phone. Cookies are needed
              to be able to provide you with a smooth functioning Service. We
              use three types of cookies, namely functional cookies, analytical
              cookies and tracking cookies. However, we will only use the
              tracking cookies with your consent. When you do not consent to the
              use of tracking cookies, the Service will work less smoothly as
              with tracking cookies.
            </p>

            <h2>Functional cookies</h2>
            <p>
              Functional cookies are cookies that are essential for the
              functioning of the Service. Without these cookies, the Service is
              unable to function properly. This means that these cookies cannot
              be turned off. There is a difference between session functional
              cookies and permanent functional cookies. The session cookies are
              deleted after you have left the Service. The permanent cookies are
              saved to prevent you from having to perform the same action all
              over again. An example of the use of permanent cookies are
              purchases which you have saved in your cart on a shopping website.
              We do not share the information obtained with these cookies with
              third parties.
            </p>

            <h2>Analytical cookies</h2>
            <p>
              Analytical cookies are cookies which are needed for the good
              functioning of the Service. Thanks to analytical cookies, we know
              what people search for on the Service, how long it takes before a
              page has been loaded and what buttons are pressed by people
              visiting the Service. Some analytical cookies may be placed
              without your consent. Other analytical cookies are only placed
              when we have your consent to do so.
            </p>
            <p>We use the following analytical cookies:</p>
            <p>
              <strong>Google analytics </strong>We use Google Analytics for our
              analytical services. Google analytics places a permanent cookie in
              order to identify your browser. By doing this, your data are
              shared with Google. We only share data with Google that we are
              allowed to share with Google according to the GDPR. We use this
              information combined with other data from other users. Due to
              this, we are unable to identify you as an individual. We have not
              given Google permission to share your data with third parties.
              However, Google can be legally obliged to share your data with
              third parties. The information which is obtained by Google is
              anonymized as much as possible. The information is transported and
              saved on servers in the United States. Google complies with the
              Privacy Principles and is a member of the Privacy Shild-programm
              from the American Ministry of Commerce. This means that there is a
              appropriate level of protection for the procession of your
              personal data.
            </p>

            <h2>
              Right of access, right of rectification, right to object and right
              to data portability
            </h2>
            <p>
              If you would like to have access to your personal data, if you
              would like to change or delete your personal data and/or if you
              would like to transfer your data or have your data transferred to
              a third party, than please contact {NAME} by sending an email to{' '}
              {NAME}.
            </p>

            <h2>Unforeseen cookies</h2>
            <p>
              We are constantly trying to keep a good overview on the cookies on
              our Service. However, it is possible that third parties place
              cookies on the Service. If that happens, you will not find these
              cookies in this overview. This mainly concerns embedded elements,
              for example cookies from an embedded YouTube video. Do you find
              cookies on the Service that are not listed in this overview? Than
              please contact us by sending an email to {EMAIL}.
            </p>

            <h2>When do we share the obtained information?</h2>
            <p>
              We do not share information that we have obtained with our
              functional cookies. Information which we have obtained with our
              analytical cookies are only shared with third parties without your
              consent after we have anonymized this information. Other
              information obtained with analytical cookies is only shared with
              third parties after we have received your consent. Information
              obtained with tracking cookies is only shared after we have
              received your consent.
            </p>

            <h2>Children</h2>
            <p>
              We do not knowingly collect personally identifiable information
              from anyone under 16 (“
              <strong>Children</strong>“). If you are a parent or guardian and
              you are aware that your Child has provided us with personal
              information, please contact us at {EMAIL}. If we become aware that
              we have collected personal information from Children without
              verification of parental consent, we will take steps to remove
              that information from our servers. If we have collected personal
              data from you when you in the period that you were a Child and if
              you would like to have this information removed, we will attempt
              to delete this data at your request. You can send this request to
              us by contacting us at {EMAIL}.
            </p>

            <h2>Change of control</h2>
            <p>
              If we sell or otherwise transfer part or the whole of {NAME}
              or our assets to another organization (e.g., in the course of a
              transaction like a merger, acquisition, bankruptcy, dissolution,
              liquidation), your information collected through the Service may
              be among the items sold or transferred. The buyer or transferee
              will honor the commitments we have made in this cookie policy.
            </p>

            <h2>Changes to this cookie policy</h2>
            <p>
              {NAME} may modify or update our cookie policy from time to time.
              We will notify you of any changes by posting the new cookie policy
              on this page. You are advised to review this cookie policy
              periodically for any changes. Changes to this cookie policy are
              effective when they are posted on this page.
            </p>
            <p>&nbsp;</p>
            <h2>How to contact us</h2>
            <p>
              If you have any questions about this cookie policy of the Service,
              please contact us by sending an email to {EMAIL}.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default CookiePolicyPage;
